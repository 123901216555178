$(document).ready(function() {
    var $menuButton  = $('.menu-button'),
        $menuIcon    = $('button.tcon'),
        $main        = $('.main'),
        $mainAndLink = $('.main, .nav-big__a'),
        $nav         = $('.nav');

    $menuButton.on('click', function() {
        $menuButton.toggleClass('menu-button--active');
        $menuIcon.toggleClass('tcon-transform');
        $main.toggleClass('main--nav-open');
        $nav.toggleClass('nav--open');
        $mainAndLink.on('click', function() {
            $menuButton.removeClass('menu-button--active');
            $menuIcon.removeClass('tcon-transform');
            $main.removeClass('main--nav-open');
            $nav.removeClass('nav--open');
            $main.off('click');
        });
    });
    // Window
    var $window = $(window);
    if (device.ios()) {
        $('.nav').height($window.height());
        if ($window.width() > 600) {
            $('.directorate').height($window.height());
        }
        $window.on('resize', function() {
            $('.nav').height($window.height());
            if ($window.width() > 600) {
                $('.directorate').height($window.height());
            }
        });
    }
    // Проверка на hash в новостях
    $window.on('hashchange load',function() {
        // Скрипт для изменения слайда по хешу
        var hash         = window.location.hash;
        if ($('main').children().hasClass('main-slider') && hash.length > 0) {
            $('.nav-big__li').removeClass('nav-big__li--active');
            $('.nav-big__li > a[href$="'+hash+'"]').parent().addClass('nav-big__li--active');
        }
    });

    // Дирекция, высчитывает ширину
    function resizeDirector() {
        var $director = $('.director__photo');
        var $directorImg = $director.children();
        var $height = $director.parent().height();
        if (device.ipad()) {
            $directorImg.height('auto');
        } else {
            $directorImg.height($height * 0.95);
        }
        if ($window.width() > 600) {
            setTimeout(function() {
                $director.css({
                    width: $height,
                    height: $height
                });
            }, 300);
        }
    }
    // Высчитываем высоту блока с картинкой
    //
    function profile() {
        var $profile = $('.profile');
        $profile.height($profile.width());
    }

    /**
     * Всякие функции для ресайза
     */
    resizeDirector();
    profile();
    $window.on('resize', function() {
        resizeDirector();
        profile();
    });
    /**
     * Слайдер для партнеров
     */
    var $partners = $('.partners');
    $partners.slick({
        unslicked: true,
        responsive: [
            {
                breakpoint: 2561,
                settings: "unslick"
            },
            {
                breakpoint: 1051,
                settings: {
                    arrows: true,
                    dots: false,
                    slidesToShow: 8,
                    slidesToScroll: 8,
                    swipeToSlide: true,
                    infinite: true
                }
            },
            {
                breakpoint: 801,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            },
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 401,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    });
    /**
     *  Табы
     */
    var $valueLi = $('.nominations__value > li'),
        $valueA = $('.nominations__value > li > a'),
        $valueBlock = $('.nominations__value-block');
    $valueA.on('click', function() {
        var $this = $(this);
        var $value = $(this).data('value');
        $valueLi.removeClass('value-active');
        $this.parent().addClass('value-active');
        $valueBlock.hide();
        $('.nominations__value-block[data-block="'+$value+'"]').fadeIn();
    });
    /**
     *  Переносит все блоки под пункты в мобильной версии
     */
    function appendBlocks() {
        if($window.width() <= 800) {
            $valueBlock.each(function(i, el) {
                var $el = $(el);
                var $data = $el.data('block');
                $('.nominations__value > li > a[data-value='+$data+']').parent().append($el);
            });
        }
    }
    appendBlocks();


    /**
     * Анимация с помощью viewportChecker
     */
    var $profiles = $('.profiles');
    $profiles.children().addClass('hidden');
    $profiles.viewportChecker({
        scrollBox: '.main__inner',
        offset: 100,
        callbackFunction: function(elem, action) {
            if (device.ios()) {
                $(elem).children().addClass('visible');
            } else {
                $(elem).children().each(function(i, el) {
                    setTimeout(function() {
                        $(el).addClass('animated fadeInUp');
                    }, i * 150);
                });
            }
        }
    });
    var $conditions = $('.conditions');
    $conditions.children().addClass('hidden');
    $conditions.viewportChecker({
        scrollBox: '.main__inner',
        offset: 100,
        callbackFunction: function(elem, action) {
            if (device.ios()) {
                $(elem).children().addClass('visible');
            } else {
                $(elem).children().each(function(i, el) {
                    setTimeout(function() {
                        $(el).addClass('animated fadeInUp');
                    }, i * 450);
                });
            }
        }
    });
    var $laureats = $('.laureats');
    $laureats.children().addClass('hidden');
    $laureats.viewportChecker({
        scrollBox: '.main__inner',
        offset: 100,
        callbackFunction: function(elem, action) {
            if (device.ios()) {
                $(elem).children().addClass('visible');
            } else {
                $(elem).children().each(function(i, el) {
                    setTimeout(function() {
                        $(el).addClass('animated fadeInLeft');
                    }, i * 100);
                });
            }
        }
    });
    var $table = $('.table-events');

    if ($window.width() >= 960 && $window.width() < 1040) {
    } else {
        $table.children().addClass('hidden');
        $table.viewportChecker({
            scrollBox: '.main__inner',
            offset: 100,
            callbackFunction: function(elem, action) {
                if (device.ios()) {
                    $(elem).children().addClass('visible');
                } else {
                    $(elem).children().each(function(i, el) {
                        setTimeout(function() {
                            $(el).addClass('animated fadeInRight');
                        }, i * 250);
                    });
                }
            }
        });
    }

    /**
     * Попытка сделать новости, №1
     */
    /**
     * Обрезаем текст
     */
    function sliceTitle() {
        var textLength = 60,
            $newsTitle = $('.news-side__title');
        $newsTitle.each(function(i, el) {
            var $this = $(el);
            $this.attr('data-fulltext', $this.text());
            if ($this.text().length > textLength) {
                var sliced = $this.text().slice(0, textLength);
                $this.text(sliced + "...");
            }
        });
    }
    sliceTitle();
    $('.news-side__read-more, .news-side__title').on('click', function(e) {
        e.preventDefault();
        // Объявил переменные
        var $this = $(this),
            $parent   = $(this).parents('.news-side__item'),
            $newsSide = $('.news-side'),
            $title    = $parent.find('.news-side__title'),
            $text     = $parent.children('.news-side__text'),
            $close    = $parent.children('.news-side__close'),
            $image    = $parent.children('.news-side__image'),
            $items    = $('.news-side__item');

        // Добавляю классы при нажатие
        $items.fadeOut(300, function() {
            $this.addClass('news-side__read-more--active');
            $newsSide.addClass('news-side--active');
            $title.addClass('news-side__title--active');
            $text.addClass('news-side__text--active');
            $close.addClass('news-side__close--active');
            $image.addClass('news-side__image--active');
            // и делаю полный текст
            $title.text($title.data('fulltext'));
            setTimeout(function() {
                $parent.fadeIn(300);
            }, 400);
        });
        // Убираю классы при нажатие
        $close.on('click', function() {
            $parent.fadeOut(300, function() {
                $this.removeClass('news-side__read-more--active');
                $newsSide.removeClass('news-side--active');
                $title.removeClass('news-side__title--active');
                $text.removeClass('news-side__text--active');
                $close.removeClass('news-side__close--active');
                $image.removeClass('news-side__image--active');
                // обрезаю текст
                sliceTitle();
                setTimeout(function() {
                    $items.fadeIn(300);
                }, 400);
            });
        });
    });
    /**
     * Переключение между таблицами программа
     */
    var $tableLinks = $('.table-events__links > li > a'),
        $tableEvents = $('.table-events'),
        $tableLinksLi = $tableLinks.parent();
    $tableLinks.on('click', function(e) {
        e.preventDefault();
        var $this = $(this),
            $dataLink = $this.data('link');
        $tableLinksLi.removeClass('section-block__special--active');
        $this.parent().addClass('section-block__special--active');
        $tableEvents.fadeOut(300, function() {
            $tableEvents.removeClass('table-events--active');
            $('.table-events[data-table='+$dataLink+']').fadeIn(300);
        });
    });
    $('.gallery__slider').slick({
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });
    /**
     * Беру высоту блока из высоты картинки
     */
    function galleryHeight() {
        var $galleryItem = $('.gallery__item'),
            $galleryImg  = $('.gallery__link img');
        if ($galleryImg.height() !== 0) {
            $galleryItem.height($galleryImg.height());
        }else {
            setTimeout(function() {
                $galleryItem.height($galleryImg.height());
            }, 300);
        }

    }
    galleryHeight();
    $('.gallery').lightGallery({
        selector: '.gallery__link',
        thumbnail:true,
        animateThumb: false,
        showThumbByDefault: false
    });
    var $gallery = $('.gallery__slider');
    var $buttons = $('.gallery__btn');
    if ($gallery.length > 0) {
        setTimeout(function() {
            $gallery.css('min-height',$gallery.outerHeight());
        }, 1000);
    }
    $buttons.click(function(e) {
        e.preventDefault();
        var $this = $(this),
            $tag = $this.data('tag'),
            $album = $this.data('album'),
            $currentBtns;
        if ($tag === undefined) {
            $tag = $buttons.filter('[data-tag]').filter(':disabled').data('tag');
            $currentBtns = $buttons.filter('[data-album]');
        }
        if ($album === undefined) {
            $album = $buttons.filter('[data-album]').filter(':disabled').data('album');
            $currentBtns = $buttons.filter('[data-tag]');
        }
        var $data = {
            tag: $tag,
            album: $album
        };

        $.ajax({
            method: "GET",
            url: '/ajax',
            data: $data,
            dataType: 'html',
            beforeSend: function() {
                $currentBtns.attr('disabled', true);
            },
            success: function(data) {
                console.log('Успешно загруженно по тегу ' + $data.tag);
                console.log('Успешно загруженно альбом ' + $data.album);
                $gallery.addClass('animated fadeOut');
                setTimeout(function() {
                    $currentBtns.attr('disabled', false);
                    $this.attr('disabled', true);
                    $gallery.slick('unslick')
                        .html(data)
                        .slick({
                            arrows: false,
                            dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        });
                    galleryHeight();
                    $('.gallery').lightGallery({
                        selector: '.gallery__link',
                        thumbnail:true,
                        animateThumb: false,
                        showThumbByDefault: false
                    });
                }, 1000);
                setTimeout(function() {
                    $gallery.removeClass('fadeOut');
                    $gallery.addClass('fadeIn');
                }, 1300);
            }
        });
    });

    $('#program-slide-video').lightGallery({
        selector: 'this'
    });
});
